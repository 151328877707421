
/**
 * @name: add
 * @author: Gzm
 * @date: 2023-05-30 15:22
 * @description：销售管理-旅游-旅游管理 新增页
 * @update: 2023-05-30 15:22
 */
import {Component, Vue} from "vue-property-decorator";
import Basic from "@/views/competitionManage/components/basic.vue";
import Detail from "@/views/competitionManage/components/detail.vue";
import {Message} from "element-ui";
import router from "@/router";
import {contestCreateApi, contestDetailApi, contestModifyApi} from "@/apis/competitionManage";
import {nextTick} from "vue";
@Component({
  components: {Detail, Basic},
  name:'competitionManageAdd'
})
export default class UserIndex extends Vue {
  // 新增数据
  tourCreateForm:any = {}
  // 基础信息是否完成
  isBasic:any = true
  activeName:string = "first"
  // handleClick(e:any){
  //   console.log(e)
  //   console.log(e.index)
  //   if (e.index == 0){
  //     this.activeName = "first"
  //     console.log(this.activeName,'tab修改')
  //   }
  //   //@ts-ignore
  //   this.$refs.basicDom.submitForm('form')
  // }

  handleTapSkip(e:any){
    this.activeName = e
  }


  basicData(e:any,v:any){
    this.isBasic = v
    if (!v){
      Message.error('基础信息填写不完善')
    }else {
      this.tourCreateForm = {...this.tourCreateForm,...e}
      this.activeName = 'second'
    }
  }

  handleAdd(e:any){
    //@ts-ignore
    this.$refs.basicDom.submitForm('form')
    this.tourCreateForm.contestDetails = e
    this.tourCreateForm.endTime = this.tourCreateForm.endTime + " 23:59:59"
    if (!this.isBasic){
      nextTick(() => {
        this.activeName = 'first'
      })
      return
      // return  Message.error('基础信息填写不完善')
    }
    if (this.tourCreateForm.id){
      console.log(this.tourCreateForm,'修改')
      // return;
      contestModifyApi(this.tourCreateForm).then(e => {
        if (e){
          Message.success('修改成功！')
          this.handelBack()
        }
      })
    }else {
      contestCreateApi(this.tourCreateForm).then(e => {
        if (e){
          Message.success('新增成功！')
          this.handelBack()
        }
      })
    }
  }

  handelBack(){
    this.$store.dispatch('delView', {path: '/competitionManage/add',name: "competitionManageAdd"});
    router.push({path:'/competitionManage/index'})
  }

  getEditData(){
    contestDetailApi(this.tourCreateForm.id).then(e => {
        this.tourCreateForm = {
          ...this.tourCreateForm,
          ...e
        }
    })
  }

  created() {
    this.tourCreateForm.id = this.$route.query.id
    if (this.$route.query.id){
      this.getEditData()
    }
  }
}
